<template>
  <v-dialog
    max-width="400px"
    v-model="model"
  >
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>
          Add {{ addDialogItemType }}
        </v-toolbar-title>
      </v-toolbar>

      <template v-if="addDialogItemType === 'folder'">
        <v-card-text class="px-5">
          <v-text-field
            label="Folder name"
            autofocus
            @keyup.enter="!inProcess && $emit('success', {type: addDialogItemType, folder: addDialogItem, item: form})"
            v-model="form.info.title"
          />

          <v-card-actions class="pa-0 justify-end">
            <v-btn
              @click="model = false"
              color="error"
              text
            >
              Cancel
            </v-btn>

            <v-btn
              @click="$emit('success', {type: addDialogItemType, folder: addDialogItem, item: form})"
              color="primary"
              :loading="inProcess"
              text
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </template>
      <template v-else>
        <v-card-text class="px-0">
          <v-list class="py-0">
            <v-list-item class="px-5">
              <v-list-item-content>
                <v-list-item-title>Test</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    addDialogItem: {
      required: true
    },
    addDialogItemType: {
      type: String,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    inProcess: {
      type: Boolean,
      default: false
    }
  },
  name: 'CreateFolderDialog',
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>